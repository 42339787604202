<template>
  <div class="index">
    <navigation msg="岗位发布"></navigation>
    <div class="title">
      <div class="left">试试电脑端快速发布</div>
      <div class="right"><van-icon name="arrow" /></div>
    </div>
    <div class="step">
      <div class="step_1">1</div>
      <div class="step_xian"></div>
      <div class="step_2">2</div>
    </div>
    <div class="step_text">
      <div class="step_text_1">创建岗位胜任力</div>
      <div class="step_text_2">交付服务情况</div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>招聘岗位名称</div>
      <div class="name">
        <van-field
          v-model="form.job_nickname"
          placeholder="请输入招聘岗位名称，如“销售专员”"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>选择岗位类型</div>
      <div class="name" @click="selectPostType">
        <div :style="form.job ? 'color: #000' : ''">
          {{ form.job || "请选择岗位类型" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show1" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择岗位类型"
        :options="optionList"
        :field-names="fieldNames"
        @close="show1 = false"
        @finish="onFinish"
      />
    </van-popup>
    <div class="item1">
      <div class="label"><span>*</span>招聘人数（最小值1）</div>
      <div class="name">
        <van-stepper
          :disabled="poststatus == 'edit'"
          v-model="form.recruit_number"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>工作年限</div>
      <div class="name" @click="show2 = true">
        <div :style="years_working ? 'color: #000' : ''">
          {{ years_working || "请选择工作年限" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show2" round position="bottom">
      <van-picker
        @confirm="onConfirm"
        title="请选择工作年限"
        show-toolbar
        :columns="columns"
        @cancel="show2 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>最低学历</div>
      <div class="name" @click="show3 = true">
        <div :style="form.education_background ? 'color: #000' : ''">
          {{ form.education_background || "请选择最低学历" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show3" round position="bottom">
      <van-picker
        @confirm="onConfirm2"
        title="请选择最低学历"
        show-toolbar
        :columns="columns2"
        @cancel="show3 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>院校要求</div>
      <div class="name" @click="show4 = true">
        <div :style="form.academy_demand ? 'color: #000' : ''">
          {{ form.academy_demand || "请选择学校要求" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show4" round position="bottom">
      <van-picker
        @confirm="onConfirm3"
        title="请选择院校要求"
        show-toolbar
        :columns="columns3"
        @cancel="show4 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>合同签署年限</div>
      <div class="name" @click="show5 = true">
        <div :style="contract_time ? 'color:#000' : ''">
          {{ contract_time || "请选择合同签署年限" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show5" round position="bottom">
      <van-picker
        @confirm="onConfirm4"
        title="请选择合同签署年限"
        show-toolbar
        :columns="columns4"
        @cancel="show5 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>试用期时间</div>
      <div class="name" @click="show14 = true">
        <div :style="probation_period ? 'color:#000' : ''">
          {{ probation_period || "试用期时间" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show14" round position="bottom">
      <van-picker
        @confirm="onConfirm14"
        title="请选择试用期时间"
        show-toolbar
        :columns="column12"
        @cancel="show14 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>试用期薪资折扣情况</div>
      <div class="name" @click="show6 = true">
        <div :style="num ? 'color:#000' : ''">
          {{ num || "请选择试用期薪资折扣情况" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show6" round position="bottom">
      <van-picker
        @confirm="onConfirm5"
        title="请选择工作年限"
        show-toolbar
        :columns="columns5"
        @cancel="show6 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>试用期薪资折扣详情</div>
      <div class="name" @click="show7 = true">
        <div :style="probation_rebate ? 'color:#000' : ''">
          {{ probation_rebate || "请选择试用期折扣" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show7" round position="bottom">
      <van-picker
        @confirm="onConfirm6"
        title="试用期薪资折扣详情"
        show-toolbar
        :columns="columns6"
        @cancel="show7 = false"
      />
    </van-popup>
    <div class="item1">
      <div class="label"><span>*</span>岗位描述</div>
      <div class="name">
        <van-field
          v-model="form.post_desc"
          placeholder="请输入岗位描述内容2000字符以内"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>任职要求</div>
      <div class="name">
        <van-field
          v-model="form.job_require"
          placeholder="请输入任职要求内容2000字符以内"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label"><span>*</span>优先考虑范围（对外发布）</div>
      <div class="name">
        <van-field
          v-model="form.priority_area"
          placeholder="请输入优先考虑范围"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>求职者自带本岗位以外的工作技能</div>
      <div
        class="name"
        v-for="(item, index) in skills_required"
        :key="index"
        @click="skills(item, index)"
      >
        <div :style="item.title ? 'color:#000' : ''">
          {{ item.title || "请选择技能类型" }}
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="solt">
        <div class="btn1" @click="addSkillsName">+ 新增技能</div>
        <div
          class="btn2"
          v-if="skills_required.length > 1"
          @click="skills_required.splice(skills_required.length - 1, 1)"
        >
          删除技能
        </div>
      </div>
    </div>
    <van-popup v-model="show15" round position="bottom">
      <van-cascader
        v-model="cascaderValue1"
        title="请选择岗位类型"
        :options="column13"
        :field-names="fieldNames1"
        @close="show15 = false"
        @finish="onFinish1"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>岗位所需证书要求</div>
      <div class="name" @click="show8 = true">
        <div :style="form.demand_certificate ? 'color:#000' : ''">
          {{ form.demand_certificate || "请选择所需证书要求" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show8" round position="bottom">
      <van-picker
        @confirm="onConfirm7"
        title="请选择所需证书要求"
        show-toolbar
        :columns="columns7"
        @cancel="show8 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label"><span>*</span>要求证书名称</div>
      <div v-if="form.demand_certificate_name">
        <div
          class="name"
          v-for="(item, index) in  form.demand_certificate_name "
          :key="index"
        >
          <van-field
            v-model="item.title"
            placeholder="Java/计算机语言方向/Python"
          />
        </div>
      </div>
      <div class="solt">
        <div class="btn1" @click="addCredentialName">+ 新增证书</div>
        <div
          class="btn2"
          v-if="form.demand_certificate_name.length > 1"
          @click="
            form.demand_certificate_name.splice(
              form.demand_certificate_name.length - 1,
              1
            )
          "
        >
          删除技能
        </div>
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>对应聘者创业经历要求</div>
      <div class="name" @click="show9 = true">
        <div :style="form.is_sartup_experience ? 'color:#000' : ''">
          {{ form.is_sartup_experience || "请选择创业经历需求" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show9" round position="bottom">
      <van-picker
        @confirm="onConfirm8"
        title="请选择对应聘者创业经历要求"
        show-toolbar
        :columns="columns8"
        @cancel="show9 = false"
      />
    </van-popup>
    <!-- //任务进度 -->
    <div class="item1">
      <div class="label"><span>*</span>目标岗位不考虑范围（对外发布）</div>
      <div class="name">
        <van-field
          v-model="form.priority_range"
          placeholder="请输入目标岗位不考虑范围"
        />
      </div>
    </div>
    <div class="item1">
      <div class="label">不考虑范围（不对外发布）</div>
      <div class="name">
        <van-field
          v-model="form.leave_out"
          placeholder="请输入目标岗位不考虑范围"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>目标岗位招聘理由</div>
      <div class="name" @click="show10 = true">
        <div :style="reason ? 'color:#000' : ''">
          {{ reason || "请选择岗位招聘理由" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show10" round position="bottom">
      <van-picker
        @confirm="onConfirm9"
        title="请选择岗位招聘理由"
        show-toolbar
        :columns="column9"
        @cancel="show10 = false"
      />
    </van-popup>
    <div class="item2">
      <div class="label">是否带过团队</div>
      <div class="name" @click="show11 = true">
        <div :style="is_team ? 'color:#000' : ''">{{ is_team || "否" }}</div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show11" round position="bottom">
      <van-picker
        @confirm="onConfirm10"
        title="是否带过团队"
        show-toolbar
        :columns="['是', '否']"
        @cancel="show11 = false"
      />
    </van-popup>
    <div class="item1">
      <div class="label">招聘部门名称</div>
      <div class="name">
        <van-field v-model="form.department" placeholder="请输入部门名称" />
      </div>
    </div>
    <div class="item1">
      <div class="label">目标岗位汇报对象职务</div>
      <div class="name">
        <van-field v-model="form.report_obj" placeholder="请输入部门名称" />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>目标岗位管理情况</div>
      <div class="name" @click="show12 = true">
        <div :style="post_management ? 'color:#000' : ''">
          {{ post_management || "请选择岗位管理情况" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show12" round position="bottom">
      <van-picker
        @confirm="onConfirm11"
        title="目标岗位管理情况"
        show-toolbar
        :columns="column10"
        @cancel="show12 = false"
      />
    </van-popup>
    <div class="item1">
      <div class="label">岗位部门培训</div>
      <div class="name">
        <van-field
          v-model="form.department_train"
          placeholder="请输入部门名称"
        />
      </div>
    </div>
    <div class="item2">
      <div class="label"><span>*</span>住宿提供情况</div>
      <div class="name" @click="show13 = true">
        <div :style="is_dorm ? 'color:#000' : ''">
          {{ is_dorm || "请选择住宿提供情况" }}
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <van-popup v-model="show13" round position="bottom">
      <van-picker
        @confirm="onConfirm12"
        title="目标岗位管理情况"
        show-toolbar
        :columns="column11"
        @cancel="show13 = false"
      />
    </van-popup>
    <div class="item1">
      <div class="label"><span>*</span>岗位职业发展规划</div>
      <div class="name">
        <van-field v-model="form.job_develop" placeholder="规划1" />
      </div>
      <div class="name">
        <van-field v-model="form.job_develop2" placeholder="规划2" />
      </div>
      <div class="name">
        <van-field v-model="form.job_develop3" placeholder="规划3" />
      </div>
    </div>
    <div class="item2">
      <div class="label">岗位培训学习方案</div>
      <div
        class="name"
        v-for="(item, index) in form.job_study_scheme"
        :key="index"
      >
        <van-field v-model="item.title" placeholder="岗位培训学习方案" />
      </div>
      <div class="solt">
        <div class="btn1" @click="addStudyscheme">+ 新增培训方案</div>
        <div
          class="btn2"
          v-if="form.job_study_scheme.length > 1"
          @click="
            form.job_study_scheme.splice(form.job_study_scheme.length - 1, 1)
          "
        >
          删除计划
        </div>
      </div>
    </div>
    <div class="item1">
      <div class="label">其他备注</div>
      <div class="name">
        <van-field v-model="form.other_note" placeholder="请输入其他备注文字" />
      </div>
    </div>
    <div style="height: 2rem"></div>
    <div class="footer">
      <div class="btn1" @click="save">保存</div>
      <div class="btn2" @click="toPage">下一步</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      skillsindex: "",
      value: "",
      value1: "",
      value2: "",
      num: "",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      show12: false,
      show13: false,
      show14: false,
      show15: false,
      cascaderValue: "",
      cascaderValue1: "",
      optionList: [],
      fieldNames: {
        text: "position_name",
        value: "position_name",
        children: "children",
      },
      fieldNames1: {
        text: "name",
        value: "name",
        children: "child",
      },
      columns: ["无年限要求", "1年以内", "1-3年", "5-10年", "10年以上"],
      columns2: [
        "无学历要求",
        "初中及以下",
        "中专/中技",
        "大专",
        "本科",
        "硕士",
        "博士",
      ],
      columns3: ["普通院校", "211优先", "985优先"],
      columns4: ["1年", "2年", "3年", "3年以上"],
      columns5: ["有折扣", "无折扣"],
      columns6: [],
      columns7: ["无证书要求", "官方证书", "非官方"],
      columns8: [
        "1段创业经历优先考虑",
        "2段创业经历优先考虑",
        "不需要创业经历",
        "几段创业经历都接受",
      ],
      column9: ["岗位扩张", "新增岗位", "人员优化", "离职替补", "其他"],
      column10: [
        "非管理岗/无下属团队（无管理能力要求）",
        "有直属管理团队（有管理要求）",
        "目标岗位有协助管理职责（有协助管理经验要求）",
      ],
      column11: [
        "不提供住宿",
        "提供宿舍",
        "提供单人公寓",
        "提供多间公寓",
        "提供专家楼",
        "提供其他住宿方式",
      ],
      column12: ["1月", "2月", "3月", "4月", "6月", "无试用期"],
      column13: [],
      fieldValue: "",
      years_working: "",
      academy_demand: "",
      probation_rebate: "",
      contract_time: "",
      reason: "",
      is_team: "否",
      post_management: "",
      is_dorm: "",
      probation_period: "",
      skills_required: [
        {
          title: "",
        },
      ],
      form: {
        job: "", // 悬赏岗位
        job_nickname: "", // 岗位称呼
        recruit_number: 1, // 招聘人数
        years_working: "", // 工作年限
        education_background: "", // 学历
        academy_demand: "", // 院校要求
        post_desc: "", // 职位描述
        priority_range: "",
        priority_area: "", // 优先考虑范围
        leave_out: "", // 不考虑范围
        contract_time: "",
        probation_rebate: "", //试用期薪资折扣详情
        demand_certificate: "",
        demand_certificate_name: [
          {
            title: "",
          },
        ], //要求证书名称
        skills_required: "", //要求具备技能
        job_study_scheme: [
          {
            title: "",
          },
        ], //岗位培训学习方案
        post_management: "",
        is_sartup_experience: "", //是否需要有创业经历
        is_team: 2, // 是否带直属团队
        department: "", // 所属部门
        department_train: "", // 岗位部门培训
        // team_num: "", // 团队规模
        is_dorm: 2, // 是否提供住宿
        // dorm_note: "", // 住宿详情备注
        reason: "", // 招聘理由
        // reason_note: "", // 招聘理由详情备注
        report_obj: "", // 汇报对象
        // enterprise_direction: "", // 企业业务发展方向
        // enterprise_future: "", // 公司未来发展趋势
        job_develop: "", // 岗位发展规划
        job_develop2: "", // 岗位发展规划
        job_develop3: "", // 岗位发展规划
        // department_culture: "", // 岗位/部门文化
        other_note: "", // 其他备注
        job_require: "", // 岗位职责
        cache_key: "",
        // interview_man: "", // 面试官职位
        // cache_key: "",
      },
    };
  },
  async mounted() {
    if (Object.keys(this.forms).length != 0) {
      Object.keys(this.forms).forEach((key) => {
        this.form[key] = this.forms[key];
      });
      this.years_working = this.columns[this.form.years_working];
      this.contract_time =
        this.form.contract_time == 1
          ? "1年"
          : this.form.contract_time == 2
          ? "2年"
          : this.form.contract_time == 3
          ? "3年"
          : 0;
      this.num = this.form.probation_rebat == 100 ? "无折扣" : "有折扣";
      this.probation_rebate = this.form.probation_rebate + "%";
      this.state_reason_1(this.form.reason);
      this.is_team = this.form.is_team == "1" ? "是" : "否";
      this.post_management =
      this.column10[Number(this.form.post_management) - 1];
      this.state_is_dorm_1(this.form.is_dorm);
      this.state_probation_period_1(this.form.probation_period);
      
      if(this.form.demand_certificate_name){
        this.form.demand_certificate_name = JSON.parse(this.form.demand_certificate_name)
      }
      if(this.form.job_study_scheme){
        this.form.job_study_scheme = JSON.parse(this.form.job_study_scheme)
      }
      console.log(this.form.demand_certificate_name)

      this.form.skills_required.split(",").forEach((item, index) => {
        if (index == 0) {
          this.skills_required = [];
        }
        this.skills_required.push({
          title: item,
        });
      });
      this.$http
        .post("/firm/v1/record/getJobSkill", {
          reqType: "record", //请求类型
          name: this.form.job.split("/")[2],
        })
        .then((res) => {
          res = JSON.parse(res.data);
          if (res.code == 0) {
            this.column13 = res.data.skillList;
          }
        });
    }

    for (let index = 100; index >= 10; index--) {
      this.columns6.push(`${index}%`);
    }
    await this.$http
      .post("/firm/v1/Position/positionList", {
        reqType: "majors_index",
      })
      .then((res) => {
        res = JSON.parse(res.data);
        this.optionList = res.data.positionList;
        console.log(this.optionList, "this.optionList");
      });
  },
  computed: {
    // 引入vuex中存储的数据
    ...mapState({
      forms: (state) => state.form,
      poststatus: (state) => state.poststatus,
    }),
  },

  methods: {
    ...mapActions([""]),
    selectPostType(){
      
      if(this.poststatus == "add"){
        console.log(111)
        this.show1 = true
      } else {
        this.show1 = false
      }
    },
    skills(item, index) {
      this.skillsindex = index;
      this.cascaderValue1 = "";
      this.show15 = true;
    },
    save() {
      let param = this.form;
      if (this.skills_required[0].title != "") {
        let str = "";
        this.skills_required.forEach((item, index) => {
          if (this.skills_required.length == index + 1) {
            str += item.title;
          } else {
            str += item.title + ",";
          }
        });
        this.form.skills_required = str;
      }
      let arr = Object.assign({}, this.forms, param);
      this.$store.commit("SET_POST", arr);
      this.$toast("保存成功");
    },
    toPage() {
      let param = this.form;
      if (this.skills_required[0].title != "") {
        let str = "";
        this.skills_required.forEach((item, index) => {
          if (this.skills_required.length == index + 1) {
            str += item.title;
          } else {
            str += item.title + ",";
          }
        });
        this.form.skills_required = str;
      }
      let arr = Object.assign({}, this.forms, param);
      this.$store.commit("SET_POST", arr);
      this.$router.push({
        path: "/step2",
      });
    },
    // 添加培训学习方案
    addStudyscheme() {
      let obj = {
        title: "",
      };
      this.form.job_study_scheme.push(obj);
    },
    // 添加证书名称
    addCredentialName() {
      let obj = {
        title: "",
      };
      
      this.form.demand_certificate_name.push(obj);
      console.log( this.form.demand_certificate_name  , 2222)
    },
    // 添加技能名称
    addSkillsName() {
      let obj = {
        title: "",
      };
      this.skills_required.push(obj);
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions)
      if (this.poststatus == "edit") {
        this.show1 = false;
        return;
      }
      this.show1 = false;
      this.form.job = selectedOptions
        .map((option) => option.position_name)
        .join("/");
        console.log(this.form.job)
      this.$http
        .post("/firm/v1/record/getJobSkill", {
          reqType: "record", //请求类型
          name: selectedOptions[2].position_name, //三级岗位
        })
        .then((res) => {
          res = JSON.parse(res.data);
          if (res.code == 0) {
            this.column13 = res.data.skillList;
          }
        });
    },
    onFinish1({ selectedOptions }) {
      this.show15 = false;
      this.skills_required[this.skillsindex].title = selectedOptions
        .map((option) => option.name)
        .join("/");
    },
    onConfirm(value, index) {
      this.years_working = value;
      this.form.years_working = index;
      this.show2 = false;
    },
    onConfirm2(value, index) {
      this.form.education_background = value;
      this.show3 = false;
    },
    onConfirm3(value, index) {
      this.form.academy_demand = value;
      this.show4 = false;
    },
    onConfirm4(value, index) {
      this.form.contract_time =
        value == "1年" ? 1 : value == "2年" ? 2 : value == "3年" ? 3 : 0;
      this.contract_time = value;
      this.show5 = false;
    },
    onConfirm5(value, index) {
      this.num = value;
      this.show6 = false;
    },
    onConfirm6(value, index) {
      this.probation_rebate = value;
      this.form.probation_rebate = "" + value.replace("%", "");
      this.show7 = false;
    },
    onConfirm7(value, index) {
      this.form.demand_certificate = value;
      this.show8 = false;
    },
    onConfirm8(value, index) {
      this.form.is_sartup_experience = value;
      this.show9 = false;
    },
    onConfirm9(value, index) {
      this.reason = value;
      this.state_reason(value);
      this.show10 = false;
    },
    onConfirm10(value, index) {
      this.is_team = value;
      this.form.is_team = value == "是" ? 1 : 2;
      this.show11 = false;
    },
    onConfirm11(value, index) {
      this.post_management = value;
      this.form.post_management =
        index == 0 ? 1 : index == 1 ? 2 : index == 2 ? 3 : "";
      this.show12 = false;
    },
    onConfirm12(value, index) {
      this.is_dorm = value;
      this.show13 = false;
      this.state_is_dorm(value);
    },
    onConfirm14(value, index) {
      this.probation_period = value;
      this.state_probation_period(value);
      this.show14 = false;
    },
    state_is_dorm(value) {
      switch (value) {
        case "不提供住宿":
          this.form.is_dorm = 0;
          break;
        case "提供宿舍":
          this.form.is_dorm = 2;
          break;
        case "提供单人公寓":
          this.form.is_dorm = 1;
          break;

        case "提供多间公寓":
          this.form.is_dorm = 4;
          break;
        case "提供专家楼":
          this.form.is_dorm = 5;
          break;
        case "提供其他住宿方式":
          this.form.is_dorm = 3;
          break;
        default:
          break;
      }
    },
    state_is_dorm_1(value) {
      switch (value) {
        case 0:
          this.is_dorm = "不提供住宿";
          break;
        case 2:
          this.is_dorm = "提供宿舍";
          break;
        case 1:
          this.is_dorm = "提供单人公寓";
          break;
        case 4:
          this.is_dorm = "提供多间公寓";
          break;
        case 5:
          this.is_dorm = "提供专家楼";
          break;
        case 3:
          this.is_dorm = "提供其他住宿方式";
          break;
        default:
          break;
      }
    },
    //招聘理由
    state_reason(value) {
      switch (value) {
        case "岗位扩张":
          this.form.reason = 0;
          break;
        case "新增岗位":
          this.form.reason = 1;
          break;
        case "人员优化":
          this.form.reason = 4;
          break;
        case "离职替补":
          this.form.reason = 2;
          break;
        default:
          break;
      }
    },
    state_reason_1(value) {
      switch (value) {
        case 0:
          this.reason = "岗位扩张";
          break;
        case 1:
          this.reason = "新增岗位";
          break;
        case 4:
          this.reason = "人员优化";
          break;
        case 2:
          this.reason = "离职替补";
          break;
        default:
          break;
      }
    },
    state_probation_period(value) {
      switch (value) {
        case "1月":
          this.form.probation_period = 1;
          break;
        case "2月":
          this.form.probation_period = 2;
          break;
        case "3月":
          this.form.probation_period = 3;
          break;
        case "4月":
          this.form.probation_period = 4;
          break;
        case "6月":
          this.form.probation_period = 6;
          break;
        case "无试用期":
          this.form.probation_period = 0;
          break;
        default:
          break;
      }
    },
    state_probation_period_1(value) {
      switch (value) {
        case 1:
          this.probation_period = "1月";
          break;
        case 2:
          this.probation_period = "2月";
          break;
        case 3:
          this.probation_period = "3月";
          break;
        case 4:
          this.probation_period = "4月";
          break;
        case 6:
          this.probation_period = "6月";
          break;
        case 0:
          this.probation_period = "无试用期";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.index {
  background-color: #fff;
}
.index /deep/ .van-picker-column__item--selected {
  color: red !important;
}
.index /deep/ .van-picker__confirm {
  color: red !important;
}
.van-cell {
  padding: 10px 0px;
}
.title {
  display: flex;
  justify-content: space-between;
  height: 0.88rem;
  padding: 0 0.28rem;
  line-height: 0.88rem;
  background: #fff0e5;
  .left,
  .right {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff3b24;
  }
}
.step {
  margin-top: 0.32rem;
  padding: 0 1.12rem;
  display: flex;
  align-items: center;
  .step_1,
  .step_2 {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
    background: #ff3b24;
    text-align: center;
    line-height: 0.42rem;
  }
  .step_xian {
    flex: 1;
    height: 8px;
    background: linear-gradient(270deg, #ffc4bd 0%, #ff3b24 100%);
  }
  .step_1 {
    transform: translateX(0.02rem);
    color: #fff;
  }
  .step_2 {
    transform: translateX(-0.02rem);
    color: #fff;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: #ff3b24;
    opacity: 0.3;
  }
}
.step_text {
  margin-bottom: 0.8rem;
  margin-top: 0.21rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.35rem;
  .step_text_1 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
  .step_text_2 {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #acb5c8;
  }
}
.index /deep/ input::placeholder {
  font-size: 0.3rem;
}
//输入框
.item1 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    display: flex;
    align-items: center;

    height: 1.12rem;
  }
}
//选择框
.item2 {
  margin: 0 0.2rem 0.57rem;
  border-bottom: 0.03rem solid #f2f2f2;
  .label {
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    span {
      margin-right: 0.1rem;
      color: #ff3b24;
    }
  }
  .name {
    height: 1.12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 0.3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #c8c8c8;
    }
  }
  .solt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .btn1 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #fff0e5;
      border-radius: 0.03rem;
      border: 0.02rem solid #ff3b24;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff3b24;
    }
    .btn2 {
      text-align: center;
      line-height: 0.71rem;
      box-sizing: border-box;
      width: 3.32rem;
      height: 0.71rem;
      background: #f1f0ef;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #6d6b6b;
      border-radius: 0.03rem;
    }
  }
}
.footer {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.29rem;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1.56rem;
  border-top: 0.02rem solid #f2f2f2;
  background: #ffffff;
  .btn1 {
    text-align: center;
    line-height: 0.95rem;
    width: 2.56rem;
    height: 0.95rem;
    background: #ffffff;
    border-radius: 0.1rem;
    border: 0.03rem solid #c3c3c3;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #989898;
  }
  .btn2 {
    text-align: center;
    line-height: 0.95rem;
    width: 4.12rem;
    height: 0.95rem;
    background: #ff3b24;
    border-radius: 0.1rem;
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>